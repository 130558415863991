import React, { useContext, useRef, useState } from "react";
import { preventNegativeValues } from "../../BulkRangeCard";
import { preventPasteNegative } from "../../../../../utils/preventPasteNegative";
import { _Table } from "../../../../../components/Table";
import { dashboardContext } from "../../../Dashboard";
import { useMutation } from "@apollo/client";
import { GET_INVOICES, REQUEST_INVOICE } from "../../../../../Qurries";
import { toast } from "react-toastify";
export const monthsName = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
var numberToWords = require("number-to-words");

export function amountToWords(amount: number) {
  const integerPart = Math.floor(amount);
  const decimalPart = Math.round((amount - integerPart) * 100);

  const integerWords = numberToWords.toWords(integerPart);
  const decimalWords =
    decimalPart > 0
      ? " and " + numberToWords.toWords(decimalPart) + " paise only"
      : " only";

  return integerWords + decimalWords;
}

function TaxInvoiceTemplate({ invoiceDetails, setGenerateInvoiceModal }: any) {
  const dateObj = new Date();
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [hsnNumber, setHsnNumber] = useState("");
  const [note, setNote] = useState("");
  const { user } = useContext(dashboardContext);
  const [requestInvoice, { loading }] = useMutation(REQUEST_INVOICE, {
    refetchQueries: [
      {
        query: GET_INVOICES,
        variables: {
          page: 1,
          limit: 100,
        },
      },
    ],
  });
  const obj = {
    invoice_date: `${dateObj.getDate()} ${monthsName[dateObj.getMonth()]} ${dateObj.getFullYear()}`,
    invoice_no: invoiceNumber,
    hsn: hsnNumber,
    amount_in_words: amountToWords(invoiceDetails.details?.total),
    note: note,
    duration: invoiceDetails.month,
    amount_without_gst: parseFloat(invoiceDetails?.details.AMOUNT_WITHOUT_GST),
    tax: parseFloat(invoiceDetails?.details?.tax),
    amount: parseFloat(invoiceDetails.details?.total),
  };

  return (
    <div className="border border-black w-full py-4">
      <h5 className=" font-semibold text-black text-xl text-center">
        Tax Invoice
      </h5>
      <div className="grid grid-cols-3 gap-x-4 p-4">
        <div className="">
          <h3 className=" text-base font-medium text-gray-600">
            Seller Details:
          </h3>
          <div>
            <ul className=" space-y-1 my-2">
              <li className="text-gray-400 text-sm uppercase font-medium">
                {user?.getUserQuery?.name ? user?.getUserQuery?.name : "NA"}
              </li>
              <li className="text-gray-400 text-sm font-medium">
                GSTIN:{" "}
                <span className="ml-1">
                  {user?.getUserQuery?.gstIn ? user?.getUserQuery?.gstIn : "NA"}
                </span>
              </li>
              <li className="text-gray-400 text-sm font-medium">
                Partner State:{" "}
                <span className="ml-1">
                  {user?.getUserQuery?.gstIn
                    ? user?.getUserQuery?.residence_state
                    : "NA"}
                </span>
              </li>
              <li className="text-gray-400 text-sm font-medium">
                Bank Details:-
              </li>
              <li className="text-gray-400 text-sm font-medium">
                Account Holder Name:{" "}
                <span className="ml-1">
                  {" "}
                  {user?.getUserQuery?.bank_details?.account_holder_name
                    ? user?.getUserQuery?.bank_details?.account_holder_name
                    : "NA"}
                </span>
              </li>
              <li className="text-gray-400 text-sm font-medium">
                Account Number:{" "}
                <span className="ml-1">
                  {user?.getUserQuery?.bank_details?.account_number
                    ? user?.getUserQuery?.bank_details?.account_number
                    : "NA"}
                </span>
              </li>
              <li className="text-gray-400 text-sm font-medium">
                IFSC:{" "}
                <span className="ml-1">
                  {user?.getUserQuery?.bank_details?.ifsc_code
                    ? user?.getUserQuery?.bank_details?.ifsc_code
                    : "NA"}
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <h3 className=" text-base font-medium text-gray-600">
            Buyer Details:
          </h3>
          <div>
            <ul className=" space-y-1 my-2">
              <li className="text-gray-400 text-sm font-medium">
                THRIVEDGE EDUTECH PRIVATE LIMITED
              </li>
              <li className="text-gray-400 text-sm font-medium">
                4th & 5th Floor, DLF Phase-5,Sector-43, Golf Course Rd,
                Gurugram. DLF QE, Gurgaon, Dlf Qe, Haryana, India, 122002
              </li>
              <li className="text-gray-400 text-sm font-medium">
                GSTIN: <span className="ml-1">06AAJCT8114C1ZJ</span>
              </li>
              <li className="text-gray-400 text-sm font-medium">
                Place of Supply: <span className="ml-1">Delhi-NCR</span>
              </li>
            </ul>
          </div>
        </div>
        <div className="">
          <h3 className=" text-base font-medium text-gray-600">
            Invoice Details:
          </h3>
          <div>
            <ul className=" space-y-2 my-2">
              <li className="text-gray-400 text-sm font-medium">
                Invoice Date:{" "}
                <span className="ml-1">
                  {dateObj.getDate()} {monthsName[dateObj.getMonth()]}{" "}
                  {dateObj.getFullYear()}
                </span>
              </li>
              <li className="text-gray-400 flex gap-x-3 text-sm font-medium">
                <span className="flex-none">Invoice No:</span>
                <input
                  className=" rounded-md max-w-md w-full px-4 py-1 focus:outline-none border border-gray-400"
                  type="text"
                  placeholder="Enter Invoice No."
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                  onKeyDown={preventNegativeValues}
                  onPaste={preventPasteNegative}
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="2"></div>
      </div>
      <div>
        <_Table
          boxPadding={" p-0"}
          minHeight={" h-auto"}
          heading={"Select Commissions"}
          bgColor=" bg-gray-50"
          data={[
            ["Commission Period", "HSN/SAC", "Commission Amount", "Tax"],
            ...[invoiceDetails]?.map((d: any, index: number) => [
              <div>{d.month}</div>,
              <div>
                <input
                  className=" rounded-md max-w-md w-full px-4 py-1 focus:outline-none border border-gray-400"
                  type="text"
                  placeholder="Enter HSN/SAC"
                  onChange={(e) => setHsnNumber(e.target.value)}
                  onKeyDown={preventNegativeValues}
                  onPaste={preventPasteNegative}
                />
              </div>,
              <div>₹{d?.details?.AMOUNT_WITHOUT_GST}</div>,
              <div>₹{d?.details?.tax}</div>,
            ]),
          ]}
          footer={
            <div className="flex justify-between px-8">
              <p className="text-black  text-sm">
                Total Amount in Words: Rupees{" "}
                {amountToWords(invoiceDetails.details?.total)}
              </p>
              <div className="w-40">
                <ul className=" space-y-1 my-2 ">
                  <li className="text-gray-400 text-xs font-medium">
                    Subtotal: ₹{invoiceDetails?.details?.AMOUNT_WITHOUT_GST}
                  </li>
                  <li className="text-gray-400 text-xs font-medium">
                    IGST@18%: ₹{invoiceDetails?.details?.tax}
                  </li>
                  <li className="text-black text-xs font-medium">
                    Total Amount: ₹{invoiceDetails?.details?.total}
                  </li>
                </ul>
              </div>
            </div>
          }
        />
      </div>
      <div className="m-4 flex flex-col gap-y-4">
        <textarea
          placeholder="Notes:(optional)"
          onChange={(e) => setNote(e.target.value)}
          className="bg-gray-100 p-4 h-28 border focus:outline-none rounded-md w-full"
        ></textarea>
        <button
          onClick={async () => {
            try {
              const res = await requestInvoice({
                variables: obj,
              });
              if (res?.data?.requestInvoice) {
                toast.success(res?.data?.requestInvoice);
                setGenerateInvoiceModal(false);
              }
            } catch (error) {
              console.log(error);
            }
          }}
          className="px-4 py-1.5 flex-none mx-auto max-w-fit bg-edviron_black disabled:bg-gray-300 rounded-md text-white font-medium text-sm"
        >
          Approve and Send Invoice
        </button>
      </div>
    </div>
  );
}

export default TaxInvoiceTemplate;
