import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_VENDOR_ALL_TRANSACTION } from "../../../../Qurries";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {
  _Table,
  Pagination,
  RowsPerPageSelect,
} from "../../../../components/Table";
import { amountFormat } from "../../../../utils/amountFormat";
import TransactionDateFilter, {
  formatDate,
} from "../../Transaction/components/TransactionDateFilter";
import { CustomDropdownIndicator } from "../../Settlement/Settlement";
import Select from "react-select";
import Institute from "../../Transaction/components/AllFilter/Institute";
import { endOfDay, set, startOfDay } from "date-fns";
import { HiMiniXMark } from "react-icons/hi2";
import { getStartAndEndOfMonth } from "../../../../utils/getStartAndEndOfMonth";
function VendorTransaction() {
  const [searchText, setSearchText] = useState<any>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [type, setType] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [transactionData, setTransactionData] = useState<any>([]);
  const [isDateRangeIsSelected, setIsDateRangeIsSelected] = useState(false);
  const [status, setStatus] = useState<any>(null);
  const [schoolId, setSchoolId] = useState<string>("");
  const [selectSchool, setSelectSchool] = useState("");
  const [refetching, setRefetching] = useState(false);
  const { startDate, endDate } = getStartAndEndOfMonth();
  const [searchFilter, setSearchFilter] = useState<any>("");
  const {
    data: vendorTransactions,
    loading,
    refetch,
  } = useQuery(GET_VENDOR_ALL_TRANSACTION, {
    onCompleted(data) {
      setTransactionData(data?.getAllVendorTransaction?.vendorsTransaction);
    },
    variables: {
      page: currentPage,
      limit: itemsPerRow.name,
      startDate: startDate,
      endDate: endDate,
    },
  });

  const refetchDataFetch = async ({
    start_date,
    end_date,
    page,
    status,
    school_id,
    custom_id,
    order_id,
  }: {
    start_date?: any;
    end_date?: any;
    page?: String;
    status?: String;
    school_id?: string;
    limit?: String;
    custom_id?: string;
    order_id?: string;
  }) => {
    try {
      setRefetching(true);
      const data = await refetch({
        endDate: end_date,
        startDate: start_date,
        page: currentPage,
        limit: itemsPerRow.name,
        status,
        school_id,
        custom_id,
        order_id,
      });
      if (data?.data?.getAllVendorTransaction?.vendorsTransaction) {
        setRefetching(false);
        setTransactionData(
          data?.data?.getAllVendorTransaction?.vendorsTransaction,
        );
      }
    } catch (error) {}
  };

  useEffect(() => {
    refetchDataFetch({
      start_date: isDateRangeIsSelected
        ? formatDate(selectedRange.startDate)
        : startDate,
      end_date: isDateRangeIsSelected
        ? formatDate(selectedRange.endDate)
        : endDate,
      status: status?.toUpperCase(),
      school_id: schoolId === "" ? "" : schoolId,
    });
  }, [status, schoolId, itemsPerRow, currentPage]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[60vh]">
          <AiOutlineLoading3Quarters className=" animate-spin text-2xl" />
        </div>
      ) : (
        <_Table
          perPage={false}
          exportBtn={true}
          heading={"Vendor Transactions"}
          copyContent={[4]}
          srNo={false}
          filter={[searchText]}
          loading={refetching || loading}
          searchBox={
            <div className="w-full ">
              <div className="flex xl:!flex-row flex-col justify-between gap-2  w-full xl:items-center items-start mb-2">
                <div className="bg-[#EEF1F6] py-3 items-center flex  px-3 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                  <input
                    className="text-xs pr-2 bg-transparent focus:outline-none w-full placeholder:font-normal"
                    type="text"
                    value={searchText}
                    placeholder="Search(Order ID...)"
                    onChange={(e) => {
                      setSearchText(e.target.value);
                    }}
                  />
                  {searchFilter !== "" && searchText.length > 3 && (
                    <HiMiniXMark
                      onClick={async () => {
                        setSearchFilter("");
                        setSearchText("");
                        refetchDataFetch({
                          start_date: startDate,
                          end_date: endDate,
                        });
                      }}
                      className="text-[#1E1B59] cursor-pointer text-md mr-2 shrink-0"
                    />
                  )}
                  <Select
                    className="border-l-2 border-gray-400"
                    options={[
                      {
                        label: "By Order ID",
                        value: "custom_order_id",
                      },
                      { label: "By Edviron Order ID", value: "order_id" },
                    ]}
                    isSearchable={false}
                    components={{
                      DropdownIndicator: CustomDropdownIndicator,
                      IndicatorSeparator: () => null,
                    }}
                    onChange={(e: any) => {
                      setSearchFilter(e.value.toLowerCase());
                      setCurrentPage(1);
                    }}
                    placeholder={
                      <div className="text-[#1E1B59] -mt-1 capitalize text-[10px]">
                        {searchFilter === ""
                          ? "filter by"
                          : searchFilter
                                .toString()
                                .toLowerCase()
                                .replaceAll("_", " ") === "order id"
                            ? "Edviron Order ID"
                            : searchFilter
                                .toString()
                                .toLowerCase()
                                .replaceAll("_", " ")}
                      </div>
                    }
                    value={searchFilter}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        backgroundColor: "transparent",
                        height: "20px",
                        border: "none",
                        boxShadow: "none",
                        cursor: "pointer",
                        minHeight: "10px",
                        padding: "0px",
                      }),
                      valueContainer: (provided) => ({
                        ...provided,
                        height: "20px",
                        width: "8rem",
                        padding: "0 8px",
                      }),
                      input: (provided) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        margin: "0",
                        padding: "0",
                        lineHeight: "20px",
                      }),
                      singleValue: (provided) => ({
                        ...provided,
                        lineHeight: "20px",
                      }),
                      indicatorsContainer: (provided) => ({
                        ...provided,
                        height: "20px",
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        fontSize: "10px",
                        cursor: "pointer",
                      }),
                    }}
                  />

                  <div className="w-10 z-50 shrink-0 flex justify-center items-center">
                    {searchText.length > 3 && refetching ? (
                      <AiOutlineLoading3Quarters className="text-xs animate-spin" />
                    ) : (
                      <i
                        onClick={() => {
                          if (searchText.length > 3 && searchFilter !== "") {
                            setCurrentPage(1);
                            refetchDataFetch({
                              order_id:
                                searchFilter === "order_id" ? searchText : null,
                              custom_id:
                                searchFilter === "custom_order_id"
                                  ? searchText
                                  : null,
                            });
                          }
                        }}
                        className=" cursor-pointer text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"
                      ></i>
                    )}
                  </div>
                </div>

                <div className="flex justify-end items-center flex-1 w-full max-w-[34rem]">
                  <TransactionDateFilter
                    setType={setDateRange}
                    type={dateRange}
                    refetch={() => {
                      refetchDataFetch({
                        start_date: formatDate(selectedRange.startDate),
                        end_date: formatDate(selectedRange.endDate),
                        status: status?.toUpperCase(),
                        school_id: schoolId === "" ? "" : schoolId,
                      });
                    }}
                    selectedRange={selectedRange}
                    setSelectedRange={setSelectedRange}
                    setIsDateRangeIsSelected={setIsDateRangeIsSelected}
                  />
                  <div className="w-full">
                    <Select
                      className="font-normal m-0 p-2 capitalize"
                      options={[
                        { label: "SUCCESS", value: "SUCCESS" },
                        { label: "PENDING", value: "PENDING" },
                        { label: "FAILED", value: "FAILED" },
                      ].map((status: any) => {
                        return {
                          label: status.label?.toLowerCase(),
                          value: status.value?.toLowerCase(),
                        };
                      })}
                      components={{
                        DropdownIndicator: CustomDropdownIndicator,
                        IndicatorSeparator: () => null,
                      }}
                      onChange={(e: any) => {
                        setStatus(e.value);
                        setCurrentPage(1);
                        // refetchDataFetch({
                        //   start_date: isDateRangeIsSelected
                        //     ? formatDate(selectedRange.startDate)
                        //     : startDate,
                        //   end_date: isDateRangeIsSelected
                        //     ? formatDate(selectedRange.endDate)
                        //     : endDate,
                        //   status: e.value?.toUpperCase(),
                        //   isCustomSearch: isCustomSearch,
                        //   searchFilter: searchFilter,
                        //   searchParams: searchText,
                        //   school_id: selectSchool === "" ? null : schoolId,
                        //   payment_modes: getPaymentMode(
                        //     filters.paymentMode,
                        //     type
                        //   ),
                        //   isQrCode: getPaymentMode(
                        //     filters.paymentMode,
                        //     type
                        //   )?.includes("qr"),
                        // });
                      }}
                      placeholder={
                        <div className="text-[#1E1B59] text-xs">Status</div>
                      }
                      value={null}
                      styles={{
                        control: (provided: any) => ({
                          ...provided,
                          backgroundColor: "#F6F8FA",
                          border: "1px solid #1B163B",
                          borderRadius: "6px",

                          minHeight: "15px",
                          margin: "0px",
                          color: "#6687FF",
                        }),
                        valueContainer: (provided: any) => ({
                          ...provided,
                          padding: "0px",
                          paddingLeft: "0.5rem",
                        }),
                        input: (provided: any) => ({
                          ...provided,
                          backgroundColor: "transparent",
                          color: "#000",
                          "::placeholder": {
                            backgroundColor: "#YourDesiredColor",
                            opacity: 1,
                          },
                          placeholder: (provided: any) => ({
                            ...provided,
                            color: "red", // Set the color of the placeholder text
                          }),
                        }),
                      }}
                    />
                  </div>
                  <div className="w-full">
                    <Institute
                      setSelectSchool={setSelectSchool}
                      setSchoolId={setSchoolId}
                    />
                  </div>
                </div>
              </div>
              <div>
                <RowsPerPageSelect
                  setItemsPerRow={setItemsPerRow}
                  itemsPerRow={itemsPerRow}
                  className=" justify-start"
                />
              </div>
              <div className="flex items-center">
                {type !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          setType("");
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                            status: status?.toUpperCase(),
                            school_id: schoolId === "" ? "" : schoolId,
                          });

                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setType("");
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {type} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {dateRange !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setSelectedRange({
                          startDate: startOfDay(new Date()),
                          endDate: endOfDay(new Date()),
                          key: "selection",
                        });
                        if (status || schoolId) {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                            status: status?.toUpperCase(),
                            school_id: schoolId === "" ? "" : schoolId,
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setDateRange("");
                          setIsDateRangeIsSelected(false);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {dateRange} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {selectSchool !== "" && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={() => {
                        if (status || isDateRangeIsSelected || type) {
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            status: status?.toUpperCase(),
                          });
                          setSelectSchool("");
                          setSchoolId("");
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setSelectSchool("");
                          setSchoolId("");
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {selectSchool} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
                {status && (
                  <div className=" text-sm m-2  max-w-fit ">
                    <button
                      onClick={async () => {
                        setCurrentPage(1);
                        if (selectSchool || isDateRangeIsSelected) {
                          refetchDataFetch({
                            start_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.startDate)
                              : startDate,
                            end_date: isDateRangeIsSelected
                              ? formatDate(selectedRange.endDate)
                              : endDate,
                            school_id: schoolId === "" ? "" : schoolId,
                          });
                          setStatus(null);
                        } else {
                          refetchDataFetch({
                            start_date: startDate,
                            end_date: endDate,
                          });
                          setStatus(null);
                        }
                      }}
                      className="bg-[#6687FFCC] font-medium flex items-center rounded-lg text-white px-4 py-2 h-full w-full"
                    >
                      {status} <HiMiniXMark className=" text-lg ml-1" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          }
          data={[
            [
              "Sr.No",
              "Name",
              "Date & Time",
              "Order ID",
              "school ID",
              "Transaction Amt",
              "Status",
            ],
            ...([
              ...transactionData?.map((d: any, index: number) => {
                return {
                  ...d,
                  serialNumber:
                    (currentPage - 1) * itemsPerRow.name + 1 + index,
                };
              }),
            ]
              // .filter((d: any) => {
              //   const arr = [
              //     d.collect_id,
              //     d.custom_id,
              //     d.school_id,
              //     d.name,
              //   ].join(",");
              //   return arr.toLowerCase().includes(searchText.toLowerCase());
              // })
              .map((transaction: any, index: number) => [
                <div>{transaction?.serialNumber}</div>,
                <div>{transaction?.name}</div>,
                <div>
                  {new Date(transaction?.createdAt).toLocaleString("hi")}
                </div>,
                <div>
                  {transaction.custom_id
                    ? transaction?.custom_id
                    : transaction?.collect_id}
                </div>,
                <div>{transaction.school_id}</div>,
                <div>{amountFormat(transaction.amount)}</div>,
                <div
                  className={`flex items-center capitalize ${
                    transaction.status.toLowerCase() === "success"
                      ? "text-[#04B521]"
                      : transaction.status.toLowerCase() === "failure" ||
                          transaction.status.toLowerCase() === "failed"
                        ? "text-[#E54F2F]"
                        : transaction.status.toLowerCase() === "pending"
                          ? "text-yellow-400"
                          : ""
                  }`}
                >
                  {transaction.status}
                </div>,
              ]) || []),
          ]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={
                  vendorTransactions?.getAllVendorTransaction?.totalPages
                }
                onPageChange={handlePageChange}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

export default VendorTransaction;
