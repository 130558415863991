export const getStartAndEndOfMonth = () => {
  const currentDate = new Date();

  const istOffset = 5.5 * 60 * 60 * 1000;
  const istDate = new Date(currentDate.getTime() + istOffset);

  const todayDate = istDate.toISOString().split("T")[0];

  const startDate = new Date(istDate.getFullYear(), istDate.getMonth());
  const formattedStartDate = new Date(startDate.getTime() + istOffset)
    .toISOString()
    .split("T")[0];

  const endDate = new Date(istDate.getFullYear(), istDate.getMonth() + 1, 1);

  const adjustedEndDate = new Date(endDate.getTime() - 1);
  const formattedEndDate = new Date(adjustedEndDate.getTime() + istOffset)
    .toISOString()
    .split("T")[0];

  return {
    startDate: formattedStartDate,
    endDate: formattedEndDate,
    currentDate: todayDate,
  };
};
