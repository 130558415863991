import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import { CREATE_VENDOR, GET_VENDORS } from "../../../../Qurries";
import { useLocation } from "react-router-dom";
import Select from "../../../../components/Graph/Select";
import { FaRegFileImage } from "react-icons/fa6";
import { MdDeleteOutline } from "react-icons/md";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Steps = ({
  title,
  number,
  array,
  stepCount,
}: {
  title: string;
  number: number;
  array: any;
  stepCount: number;
}) => {
  return (
    <>
      <div
        className={
          "flex items-center w-full lg:text-xs text-[10px] " +
          (number === array.length - 1 ? " max-w-fit" : " ")
        }
      >
        <span
          className={
            "rounded-full mr-2 shrink-0 lg:h-7 lg:w-7 h-5 w-5 flex justify-center items-center " +
            (number === stepCount - 1
              ? " bg-indigo-500 text-white"
              : " bg-gray-300")
          }
        >
          {number + 1}
        </span>
        <span
          className={
            "shrink-0 mr-2 " +
            (number === stepCount - 1 ? " text-indigo-600 font-bold" : " ")
          }
        >
          {title}
        </span>
        {number !== array.length - 1 && (
          <span
            className={
              "h-[2px] shrink mx-auto w-2/3  " +
              (number === stepCount - 1
                ? " bg-indigo-600 font-bold"
                : " bg-gray-300")
            }
          ></span>
        )}
      </div>
    </>
  );
};

function VendorStepForm({ setShowVender }: any) {
  let { state } = useLocation();

  const [error, setError] = useState({ name: "", error: "" });
  // const [accountType, setAccountType] = useState({
  //   name: "Individual",
  //   value: "Individual",
  // });
  const [businessType, setBusinessType] = useState({
    name: "Grocery",
    value: "Grocery",
  });
  const [vendorDetails, setVendorDetails] = useState({
    vendorName: "",
    phoneNo: "",
    emailId: "",
    accountHolderName: "",
    accountNo: "",
    ifscCode: "",
    panCardNo: "",
    gstNo: "",
    chequeBase64: "",
    chequeExtension: "",
    fileName: "",
  });
  const [isValid, setIsValid] = useState({
    phoneNo: true,
    emailId: true,
    accountNo: true,
    ifscCode: true,
    panCardNo: true,
    gstNo: true,
    uploadFile: true,
  });

  const regexPatterns: any = {
    emailId: /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
    phoneNo: /^\d{10}$/,
    gstNo: /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1})$/,
    accountNo: /^\d{9,18}$/,
    ifscCode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    panCardNo: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  };

  const handleChange = (e: any) => {
    const { name, value }: any = e.target;
    if (name === "phoneNo" && value.length > 10) {
      return;
    }
    if (name === "accountNo" && value.length > 18) {
      return;
    }
    if (
      [
        "phoneNo",
        "emailId",
        "accountNo",
        "ifscCode",
        "panCardNo",
        "gstNo",
      ].includes(name)
    ) {
      setIsValid((prevValidity) => ({
        ...prevValidity,
        [name]: regexPatterns[name].test(value),
      }));
    }
    setVendorDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (vendorDetails.fileName === "") {
      setIsValid((prevValidity) => ({
        ...prevValidity,
        uploadFile: false,
      }));

      return;
    }
    try {
      const res = await createVendor({
        variables: {
          school_id: state.schoolId,
          chequeBase64: vendorDetails.chequeBase64,
          chequeExtension: vendorDetails.chequeExtension,
          vendor_info: {
            status: "ACTIVE",
            name: vendorDetails.vendorName,
            email: vendorDetails.emailId,
            phone: vendorDetails.phoneNo,
            verify_account: true,
            dashboard_access: true,
            schedule_option: 1,

            bank: {
              account_number: vendorDetails.accountNo,
              ifsc: vendorDetails.ifscCode,
              account_holder: vendorDetails.accountHolderName,
            },
            kyc_details: {
              account_type: "Eduction",
              business_type: businessType.value,
              gst: vendorDetails.gstNo || "",
              pan: vendorDetails.panCardNo || "",
            },
          },
        },
      });
      if (res.data.createVendor) {
        toast.success(res.data.createVendor);
        setShowVender(false);
      }
    } catch (error: any) {
      toast.error(error);
    }
  };

  function handleFileSelect(event: any, type: string) {
    var selectedFile = event;
    var fileName = event.name;

    let fileExtension: any;
    if (type.includes("application/pdf")) {
      fileExtension = "pdf";
    } else if (type.includes("image/jpeg")) {
      fileExtension = "jpeg";
    } else if (type.includes("image/png")) {
      fileExtension = "png";
    }

    if (selectedFile) {
      var reader = new FileReader();
      reader.onload = function (event) {
        var selectedFile: any = selectedFile;
        if (typeof event.target?.result === "string") {
          var selectedFileBase64 = event.target.result.split(",")[1];
          setVendorDetails((prevState) => ({
            ...prevState,
            chequeBase64: selectedFileBase64,
            chequeExtension: fileExtension,
            fileName,
          }));
          setIsValid((prevValidity) => ({
            ...prevValidity,
            uploadFile: true,
          }));
        }
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  const [stepCount, setStepCount] = useState(1);
  const steps = ["Vendor Details", "Account Details"];
  const [createVendor, { loading }] = useMutation(CREATE_VENDOR, {
    refetchQueries: [
      {
        query: GET_VENDORS,
        variables: { page: 1, limit: 100 },
      },
    ],
  });
  return (
    <div className="p-8">
      <div className="w-full space-y-4 bg-white h-full p-4">
        {/* <div className="flex items-center justify-between gap-4">
          {steps.map((item, number) => {
            return (
              <Steps
                array={steps}
                title={item}
                number={number}
                stepCount={stepCount}
              />
            );
          })}
        </div> */}

        <div className="flex justify-between items-center">
          <h5>{steps[stepCount - 1]}</h5>
          <h6 className="text-[11px] bg-gray-400 px-2 py-[2px] rounded-[2px] text-white ">
            Step {stepCount}/{steps.length}
          </h6>
        </div>

        {stepCount === 1 && (
          <div className="grid  grid-cols-2 text-sm gap-4">
            <label
              htmlFor="vendorName"
              className="flex flex-col text-xs gap-y-2 col-span-2"
            >
              Vendor Name
              <input
                type="text"
                id="vendorName"
                name="vendorName"
                value={vendorDetails.vendorName}
                onChange={handleChange}
                placeholder="Enter Vendor Name"
                className="px-4 py-2 border focus:outline-none rounded-md border-gray-300"
              />
            </label>
            <label
              htmlFor="phoneNo"
              className="flex flex-col text-xs gap-y-2 md:col-span-1 col-span-2"
            >
              Phone No.
              <input
                type="number"
                id="phoneNo"
                name="phoneNo"
                value={vendorDetails.phoneNo}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.phoneNo.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      phoneNo: true,
                    }));
                  }
                }}
                placeholder="Enter Phone No"
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.phoneNo ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.phoneNo && (
                <p className="text-red-500 text-xs">
                  Please enter a valid 10-digit phone number
                </p>
              )}
            </label>
            <label
              htmlFor="email"
              className="flex flex-col text-xs gap-y-2 md:col-span-1 col-span-2"
            >
              Email ID
              <input
                type="email"
                id="email"
                name="emailId"
                value={vendorDetails.emailId}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.emailId.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      emailId: true,
                    }));
                  }
                }}
                placeholder="Enter Email ID"
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.emailId ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.emailId && (
                <p className="text-red-500 text-xs">
                  Please enter a valid email address (e.g., name@example.com).
                </p>
              )}
            </label>
            <div className="flex  flex-col text-xs gap-y-2 md:col-span-1 col-span-2">
              Business Type
              <Select
                selected={businessType}
                setSelected={setBusinessType}
                className="border border-gray-300 rounded-md text-xs"
                selectedValueStyle="  px-2 py-2 text-[10px]"
                options={[
                  "Grocery",
                  "Jewellery",
                  "Miscellaneous",
                  "Web host/Domain seller",
                  "E-commerce",
                  "Online Gaming",
                  "Society/Trust/Club/Association",
                  "Mutual funds/Broking",
                  "B2B",
                  "Real Estate",
                  "Housing",
                  "Rentals",
                  "Utilities",
                  "Travel and Hospitality",
                  "Education",
                  "Food and Beverages",
                  "NBFCs/Organizations into Lending",
                  "Chit Funds",
                  "Non Profit/NGO",
                  "Financial Services",
                  "Government",
                  "Readymade",
                  "SaaS",
                  "Professional Services (Doctors, Lawyers, Architects, CAs, and other Professionals)",
                  "Open and Semi Open Wallet",
                  "Social Media and Entertainment",
                  "Pan shop",
                  "Telecom",
                  "Digital Goods",
                  "Insurance",
                  "Pharmacy",
                  "Healthcare",
                  "Retail and Shopping",
                  "Gaming",
                  "Logistics",
                ].map((d: any) => {
                  return { name: d, value: d };
                })}
              />
            </div>

            {/* <label className="flex flex-col text-xs gap-y-2 md:col-span-1 col-span-2">
                Account Type
                <Select
                  selected={businessType}
                  setSelected={setBusinessType}
                  className="border border-gray-300 rounded-md text-xs"
                  selectedValueStyle="  px-2 py-2 text-[10px]"
                  options={[{ name: "Eduction", value: "" }].map((d: any) => {
                    return { name: d.name, value: d.value };
                  })}
                />
              </label> */}
          </div>
        )}
        {stepCount === 2 && (
          <div className="grid grid-cols-2 text-sm gap-4">
            <label
              htmlFor="account-holder"
              className="flex flex-col text-xs gap-y-2 col-span-2"
            >
              Account Holder Name
              <input
                type="text"
                id="account-holder"
                name="accountHolderName"
                value={vendorDetails.accountHolderName}
                onChange={handleChange}
                placeholder="Enter Account Holder Name"
                className="px-4 py-2 border focus:outline-none rounded-md border-gray-300"
              />
            </label>
            <label
              htmlFor="accountNo"
              className="flex flex-col text-xs gap-y-2 lg:col-span-1 col-span-2 "
            >
              Account Number
              <input
                type="number"
                id="accountNo"
                name="accountNo"
                value={vendorDetails.accountNo}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.accountNo.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      accountNo: true,
                    }));
                  }
                }}
                placeholder="Enter Account Number"
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.accountNo ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.accountNo && (
                <p className="text-red-500 text-xs">
                  Please enter a valid Account No.
                </p>
              )}
            </label>
            <label
              htmlFor="ifsc"
              className="flex flex-col text-xs gap-y-2 lg:col-span-1 col-span-2"
            >
              Ifsc Code.
              <input
                type="text"
                id="ifsc"
                name="ifscCode"
                value={vendorDetails.ifscCode}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.ifscCode.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      ifscCode: true,
                    }));
                  }
                }}
                placeholder="Enter IFSC Code"
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.ifscCode ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.ifscCode && (
                <p className="text-red-500 text-xs">
                  Please enter a valid IFCS Code.
                </p>
              )}
            </label>
            <div className="col-span-2 gap-y-2 flex flex-col">
              <p className="text-xs">Upload Cancel Cheque</p>
              <div
                className={
                  "border-2  flex justify-center items-center rounded-md p-2 border-dashed " +
                  (!isValid.uploadFile ? " border-red-500" : " border-gray-300")
                }
              >
                {vendorDetails.fileName !== "" ? (
                  <div className="flex py-2 px-4 gap-x-2 text-[#505E8E] items-center text-xs bg-[#EAECF4] rounded-md">
                    <FaRegFileImage className="shrink-0 text-[15px]" />
                    <p className=" max-w-[10rem] truncate text-xs">
                      {vendorDetails.fileName}
                    </p>
                    <MdDeleteOutline
                      onClick={() => {
                        setVendorDetails((prevState) => ({
                          ...prevState,
                          chequeBase64: "",
                          chequeExtension: "",
                          fileName: "",
                        }));
                      }}
                      className="shrink-0 text-lg cursor-pointer"
                    />
                  </div>
                ) : (
                  <div className="flex flex-col items-center">
                    <label htmlFor="upload-file">
                      <FaCloudUploadAlt className=" text-indigo-500 cursor-pointer text-2xl" />
                    </label>
                    <input
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={async (e: any) => {
                        if (
                          e.target.files &&
                          e.target?.files[0]?.size < 10000000
                        ) {
                          handleFileSelect(
                            e.target.files[0],
                            e.target?.files[0].type,
                          );
                        } else {
                          toast.error(
                            "File size exceeds maximum limit of 10 MB",
                          );
                        }
                        e.target.value = "";
                      }}
                      type="file"
                      hidden
                      id="upload-file"
                    />

                    <p className="text-[10px]">
                      <span className=" text-[#00AA11]">Upload</span> file
                      <span className="text-[#B8C0CD]"> (.pdf/.jpg/.jpeg)</span>
                    </p>
                  </div>
                )}
              </div>
              {!isValid.uploadFile && (
                <p className="text-red-500 text-xs">Please Upload File!</p>
              )}
            </div>
            <div className="col-span-2">
              <h3>Business Details</h3>
            </div>
            <label
              htmlFor="pan-no"
              className="flex flex-col text-xs gap-y-2 lg:col-span-1 col-span-2"
            >
              Pan No.
              <input
                type="text"
                id="pan-no"
                name="panCardNo"
                value={vendorDetails.panCardNo}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.panCardNo.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      panCardNo: true,
                    }));
                  }
                }}
                placeholder="Enter Pan No."
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.panCardNo ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.panCardNo && (
                <p className="text-red-500 text-xs">
                  Please enter a valid Pan Card No.
                </p>
              )}
            </label>
            <label
              htmlFor="gst-no"
              className="flex flex-col text-xs gap-y-2 lg:col-span-1 col-span-2"
            >
              Gst No.
              <input
                type="text"
                id="gst-no"
                name="gstNo"
                value={vendorDetails.gstNo}
                onChange={handleChange}
                onBlur={() => {
                  if (!vendorDetails.gstNo.length) {
                    setIsValid((prevValidity) => ({
                      ...prevValidity,
                      gstNo: true,
                    }));
                  }
                }}
                placeholder="Enter GST No."
                className={
                  "px-4 py-2 border focus:outline-none rounded-md " +
                  (!isValid.gstNo ? " border-red-500" : " border-gray-300 ")
                }
              />
              {!isValid.gstNo && (
                <p className="text-red-500 text-xs">
                  Please enter a valid GST (GSTIN) No.
                </p>
              )}
            </label>
          </div>
        )}
        <div className="flex justify-end items-center mt-4">
          <div className="flex gap-x-4 text-sm">
            {stepCount === 1 ? (
              <button
                onClick={() => {
                  setShowVender(false);
                }}
                type="button"
                className="text-indigo-600"
              >
                Cancel
              </button>
            ) : (
              <button
                onClick={() => {
                  setStepCount(stepCount - 1);
                }}
                type="button"
                className="px-4 py-1.5  text-black hover:bg-indigo-500 hover:text-white rounded-md"
              >
                Previous
              </button>
            )}
            {stepCount === steps.length ? (
              <button
                type="submit"
                onClick={handleSubmit}
                disabled={
                  !(
                    vendorDetails.vendorName !== "" &&
                    vendorDetails.phoneNo !== "" &&
                    vendorDetails.emailId !== "" &&
                    vendorDetails.accountHolderName !== "" &&
                    vendorDetails.accountNo !== "" &&
                    vendorDetails.ifscCode !== "" &&
                    (vendorDetails.gstNo !== "" ||
                      vendorDetails.panCardNo !== "")
                  )
                }
                className="px-8  py-1.5 bg-indigo-600 disabled:bg-gray-400 text-white rounded-md"
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className=" animate-spin text-xl" />
                ) : (
                  "Submit"
                )}
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  !(
                    vendorDetails.vendorName !== "" &&
                    vendorDetails.phoneNo !== "" &&
                    vendorDetails.emailId !== "" &&
                    !error.error.length
                  )
                }
                onClick={() => {
                  setStepCount(stepCount + 1);
                }}
                className="px-8  py-1.5 bg-indigo-600 disabled:bg-gray-400 text-white rounded-md"
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorStepForm;
