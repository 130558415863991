import React, { useEffect, useState } from "react";
import {
  _Table,
  Pagination,
  RowsPerPageSelect,
} from "../../../../components/Table";
import RateChargeModal from "../../../../components/Modal/RateChargeModal";
import { useQuery } from "@apollo/client";
import { GET_WEBHOOK_LOGS } from "../../../../Qurries";
import { getStartAndEndOfMonth } from "../../../../utils/getStartAndEndOfMonth";
import { MdArrowBackIos } from "react-icons/md";
import { toast } from "react-toastify";
import { FaCheck } from "react-icons/fa";
import { GoPaste } from "react-icons/go";
import SyntaxHighlighter from "react-syntax-highlighter";
import Select from "react-select";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { HiMiniXMark } from "react-icons/hi2";
import { CustomDropdownIndicator } from "../../Settlement/Settlement";
export function Block({ children, className, lang, copyContent }: any) {
  const [copyButtonShow, setCopyButtonShow] = useState(false);

  const codeString = `${children}`;
  const handleCopyContent = (content: any) => {
    const CONTENT = content.props ? content.props.children : content;
    navigator.clipboard
      .writeText(CONTENT)
      .then(() => {
        toast.success("Copied to clipboard");
      })
      .catch((err) => {
        toast.error("Error while copying");
      });
  };
  return (
    <div className="max-w-2xl mt-4 min-w-[25rem] rounded-md bg-black m-2 p-4 overflow-hidden">
      {copyContent && (
        <div className="flex text-white justify-end items-center px-4 py-1.5 bg-ed-blue-dark">
          {copyButtonShow ? (
            <span className=" p-[5px]  inline-flex items-center">
              <FaCheck className=" mr-2 text-xl" />
              Copied!
            </span>
          ) : (
            <button
              onClick={() => {
                handleCopyContent(children);
                setCopyButtonShow(true);
                setTimeout(() => {
                  setCopyButtonShow(false);
                }, 3000);
              }}
              className=" inline-flex p-[5px]   items-center"
            >
              <span>
                <GoPaste className="text-xl mr-2" />
              </span>
              Copy Code
            </button>
          )}
        </div>
      )}
      <SyntaxHighlighter
        language={lang ? lang : "json"}
        style={atomOneDark}
        customStyle={{
          padding: "25px",
          backgroundColor: "black",
        }}
        wrapLongLines={true}
      >
        {codeString}
      </SyntaxHighlighter>
    </div>
  );
}
function WebhookLogs() {
  const [openModal, setOpenModal] = useState(false);
  const [webhookDetails, setWebHookDetails] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [refetchLoading, setRefetchLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [itemsPerRow, setItemsPerRow] = useState({ name: 10 });
  const [selectedRange, setSelectedRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const { currentDate } = getStartAndEndOfMonth();
  const [searchText, setSearchText] = useState<string>("");
  const [searchFilter, setSearchFilter] = useState<any>("");

  const { data, loading, refetch } = useQuery(GET_WEBHOOK_LOGS, {
    variables: {
      startDate: currentDate,
      endDate: currentDate,
      page: currentPage,
      limit: 10,
    },
  });

  const refetchDataFetch = async ({
    start_date,
    end_date,
    page,
    school_id,
    collect_id,
    limit,
    custom_id,
  }: {
    start_date?: any;
    end_date?: any;
    page?: number;
    school_id?: String;
    collect_id?: String;
    limit?: number;
    custom_id?: String;
  }) => {
    setRefetchLoading(true);
    try {
      const res = await refetch({
        startDate: start_date,
        endDate: end_date,
        page,
        school_id,
        collect_id,
        limit,
        custom_id,
      });
      if (res) {
        setRefetchLoading(false);
      }
    } catch (error) {
      if (error) {
        setRefetchLoading(false);
      }
    }
  };

  useEffect(() => {
    refetchDataFetch({
      start_date: currentDate,
      end_date: currentDate,
      page: currentPage,
      limit: itemsPerRow.name,
    });
  }, [currentPage, itemsPerRow.name]);

  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };

  return (
    <div className="p-4  w-full mt-4">
      <RateChargeModal open={openModal} setOpen={setOpenModal}>
        <div className=" pt-4 bg-white px-8">
          <div className=" bg-white  flex items-center justify-between">
            <p
              onClick={() => {
                setOpenModal(false);
              }}
              className="flex text-xs cursor-pointer text-[#505E8E] items-center"
            >
              <MdArrowBackIos className="mr-2" />
              Back
            </p>
            <p className="font-semibold text-xl text-edviron_black">
              Webhook Details
            </p>
          </div>
          <div className="mt-2 ">
            <div className="grid grid-cols-3 gap-2 max-w-md">
              <div className=" col-span-1">
                <h3 className="text-gray-500 font-medium tracking-wide">
                  Webhook Type
                </h3>
              </div>
              <div className="font-semibold text-gray-700  col-span-2">
                {webhookDetails?.webhookType
                  ? webhookDetails?.webhookType
                  : "-"}
              </div>
              <div className=" col-span-1">
                <h3 className="text-gray-500 font-medium tracking-wide">
                  Status
                </h3>
              </div>
              <div className="font-semibold text-gray-700  col-span-2">
                {webhookDetails?.status_code}
              </div>
              <div className=" col-span-1">
                <h3 className="text-gray-500 font-medium tracking-wide">
                  Order ID
                </h3>
              </div>
              <div className="font-semibold text-gray-700  col-span-2">
                {webhookDetails?._id}
              </div>
              <div className=" col-span-1">
                <h3 className="text-gray-500 font-medium tracking-wide">
                  Endpoint
                </h3>
              </div>
              <div className="font-semibold text-gray-700  col-span-2 truncate">
                {webhookDetails?.webhook_url}
              </div>
              <div className=" col-span-1">
                <h3 className="text-gray-500 font-medium tracking-wide">
                  Attempt At
                </h3>
              </div>
              <div className="font-semibold text-gray-700  col-span-2">
                {webhookDetails?.triggered_time}
              </div>
            </div>
            <div className=" bg-white space-x-6 mt-2">
              <button
                onClick={() => setActiveTab(1)}
                className={
                  activeTab === 1
                    ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px] "
                    : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
                }
              >
                Details
              </button>
              <button
                onClick={() => setActiveTab(2)}
                className={
                  activeTab === 2
                    ? "text-[#6687FF] border-b-2 border-[#6687FF] px-4 py-3 font-semibold text-[12px] "
                    : "  py-3 px-4 font-semibold bg-transparent text-[12px] text-left  text-[#777777]"
                }
              >
                Payload
              </button>
            </div>
          </div>
        </div>
        <div>
          {activeTab === 1 && (
            <div>
              <div className="grid grid-cols-3 gap-2 p-8 max-w-md">
                <div className=" col-span-1">
                  <h3 className="text-gray-500 font-medium tracking-wide">
                    Response
                  </h3>
                </div>
                <div className="font-semibold text-gray-700  col-span-2">
                  {webhookDetails?.response ? webhookDetails?.response : "-"}
                </div>
                <div className=" col-span-1">
                  <h3 className="text-gray-500 font-medium tracking-wide">
                    Message
                  </h3>
                </div>
                <div className="font-semibold text-gray-700  col-span-2">
                  {webhookDetails?.isSuccess === true ? "SUCCESS" : "FAILED"}
                </div>
                <div className=" col-span-1">
                  <h3 className="text-gray-500 font-medium tracking-wide">
                    Time
                  </h3>
                </div>
                <div className="font-semibold text-gray-700  col-span-2">
                  {webhookDetails?.triggered_time}
                </div>
              </div>
            </div>
          )}
          {activeTab === 2 && (
            <div className="ml-6 mt-6">
              <Block
                copyContent={true}
                lang="json"
                className="text-sm px-2 py-[6px] dark:bg-gray-700 dark:text-white"
              >
                {JSON.parse(JSON.stringify(webhookDetails.payload, null, 2))}
              </Block>
            </div>
          )}
        </div>
      </RateChargeModal>
      {data?.GetWEbhookLogs?.erp_webhooks_logs.length > 0 && (
        <_Table
          heading={"Webhook Logs"}
          copyRight={false}
          description={
            <div>
              <div className="bg-[#EEF1F6] py-3 items-center flex  px-3 xl:max-w-md max-w-[34rem] w-full rounded-lg">
                <input
                  className="text-xs pr-2 bg-transparent focus:outline-none w-full placeholder:font-normal"
                  type="text"
                  value={searchText}
                  placeholder=" Search(Order ID...)"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
                {searchText.length > 3 && (
                  <HiMiniXMark
                    onClick={async () => {
                      setSearchFilter("");
                      setSearchText("");

                      refetchDataFetch({
                        start_date: currentDate,
                        end_date: currentDate,
                      });
                    }}
                    className="text-[#1E1B59] cursor-pointer text-md mr-2 shrink-0"
                  />
                )}
                <Select
                  className="border-l-2 border-gray-400"
                  options={[
                    {
                      label: "By Custom Order ID",
                      value: "custom_order_id",
                    },
                    { label: "By Order ID", value: "order_id" },
                  ].map((status: any) => {
                    return {
                      label: status.label?.toLowerCase(),
                      value: status.value?.toLowerCase(),
                    };
                  })}
                  isSearchable={false}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e: any) => {
                    setSearchFilter(e.value);
                    setCurrentPage(1);
                  }}
                  placeholder={
                    <div className="text-[#1E1B59] -mt-1 capitalize text-[10px]">
                      {searchFilter === ""
                        ? "filter by"
                        : searchFilter
                            .toString()
                            .toLowerCase()
                            .replaceAll("_", " ")}
                    </div>
                  }
                  value={searchFilter}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                      height: "20px",
                      border: "none",
                      boxShadow: "none",
                      cursor: "pointer",
                      minHeight: "10px",
                      padding: "0px",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      height: "20px",
                      width: "8rem",
                      padding: "0 8px",
                    }),
                    input: (provided) => ({
                      ...provided,
                      margin: "0",
                      padding: "0",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      margin: "0",
                      padding: "0",
                      lineHeight: "20px",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      lineHeight: "20px",
                    }),
                    indicatorsContainer: (provided) => ({
                      ...provided,
                      height: "20px",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      fontSize: "10px",
                      cursor: "pointer",
                    }),
                  }}
                />

                <div className="w-10 z-50 shrink-0 flex justify-center items-center">
                  {searchText.length > 3 && loading ? (
                    <AiOutlineLoading3Quarters className="text-xs animate-spin" />
                  ) : (
                    <i
                      onClick={() => {
                        if (searchText.length > 3 && searchFilter !== "") {
                          setCurrentPage(1);

                          refetchDataFetch({
                            collect_id:
                              searchFilter === "order_id" ? searchText : "",
                            custom_id:
                              searchFilter === "custom_order_id"
                                ? searchText
                                : "",
                          });
                        }
                      }}
                      className=" cursor-pointer text-edvion_black text-opacity-50 fa-solid text-xs fa-magnifying-glass"
                    ></i>
                  )}
                </div>
              </div>
              <RowsPerPageSelect
                setItemsPerRow={setItemsPerRow}
                itemsPerRow={itemsPerRow}
                className=" justify-end"
              />
            </div>
          }
          pagination={false}
          loading={loading || refetchLoading}
          copyContent={[2]}
          data={[
            [
              "Sr.No",
              "Collect ID",
              "Endpoint",
              "Status",
              "Webhook Type",
              "Date",
            ],
            ...([
              ...data?.GetWEbhookLogs?.erp_webhooks_logs?.map(
                (d: any, i: number) => {
                  return {
                    ...d,
                    serialNumber: (currentPage - 1) * itemsPerRow.name + 1 + i,
                  };
                },
              ),
            ]?.map((d: any) => [
              <div>{d?.serialNumber}</div>,
              <div
                onClick={() => {
                  setOpenModal(true);
                  setWebHookDetails(d);
                  setActiveTab(1);
                }}
                className="cursor-pointer"
              >
                {d?.collect_id}
              </div>,

              <div
                onClick={() => {
                  setOpenModal(true);
                  setWebHookDetails(d);
                  setActiveTab(1);
                }}
                className="truncate cursor-pointer"
              >
                {d?.webhook_url}
              </div>,
              <div
                onClick={() => {
                  setOpenModal(true);
                  setWebHookDetails(d);
                  setActiveTab(1);
                }}
                className={
                  "cursor-pointer " +
                  (d?.status_code === "200" ? "text-green-500" : "text-red-500")
                }
              >
                {d?.status_code}
              </div>,
              <div
                onClick={() => {
                  setOpenModal(true);
                  setWebHookDetails(d);
                  setActiveTab(1);
                }}
                className="ml-5 cursor-pointer"
              >
                {d?.webhookType ? d?.webhookType : "-"}
              </div>,

              <div
                onClick={() => {
                  setOpenModal(true);
                  setWebHookDetails(d);
                  setActiveTab(1);
                }}
                className="cursor-pointer"
              >
                {d?.triggered_time}
              </div>,
            ]) || []),
          ]}
          footer={
            <div className="flex justify-center items-center">
              <Pagination
                currentPage={currentPage}
                totalPages={Math.ceil(data?.GetWEbhookLogs?.totalRecords)}
                onPageChange={handlePageChange}
              />
            </div>
          }
        />
      )}
    </div>
  );
}

export default WebhookLogs;
