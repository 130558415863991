/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useState } from "react";
import Card from "../../../components/Card/Card";
import LineGraph from "../../../components/Graph/LineGraph";
import { useQuery } from "@apollo/client";
import {
  GET_BATCH_TRANSACTION,
  GET_PARTNER_PROFILE_DATA,
  GET_TRANSACTION_REPORT,
} from "../../../Qurries";

import {
  filterTransactionsByMonth,
  filterTransactionsByQuarter,
  getRecentTransactions,
  getSettlementAmount,
  sumTransactionAmountOfToday,
} from "./Helper/filterData";
import { dashboardContext } from "../Dashboard";
import { _Table } from "../../../components/Table";
import { NavLink, useNavigate } from "react-router-dom";
import RingGraph from "../../../components/RingGraph/RingGraph";
import ToolTip from "../../../components/ToolTip";
import { MdContentCopy } from "react-icons/md";
import { handleCopyContent } from "../components/school/AllSchoolList";
import { Access } from "../TeamMember/TeamMembers";
import CopyRight from "../../../components/CopyRight";
import { getStartAndEndOfMonth } from "../../../utils/getStartAndEndOfMonth";
import axios from "axios";

function Overview() {
  const { startDate, endDate, currentDate } = getStartAndEndOfMonth();
  const [transactionAmountDetails, setTransactionAmountDetails] =
    useState<any>(null);
  const { settlementData, commissionTotalAmount, user } =
    useContext(dashboardContext);
  const { data: partnerData, loading: partnerDataLoading } = useQuery(
    GET_PARTNER_PROFILE_DATA,
  );

  useEffect(() => {
    const GET_TRANSACTION_AMOUNT = async (
      start_date: String,
      end_date: String,
      trustee_id: String,
      school_id: String,
      status: String,
    ) => {
      const res = await axios.get(
        `${process.env.REACT_APP_PAYMENT_BACKEND_URL}/edviron-pg/get-transaction-report-batched?start_date=${start_date}&end_date=${end_date}&trustee_id=${trustee_id}&school_id=${school_id}&status=${status}`,
      );

      setTransactionAmountDetails(res.data.transactions[0]);
    };

    GET_TRANSACTION_AMOUNT(
      currentDate,
      currentDate,
      user?.getUserQuery?.trustee_id,
      "",
      "SUCCESS",
    );
  }, []);

  const { data: recentTransaction, loading: recentLoading } = useQuery(
    GET_TRANSACTION_REPORT,
    {
      variables: {
        startDate: startDate,
        endDate: endDate,
        limit: "100",
      },
    },
  );
  const { data: transactionReport, loading: transactionReportLoading } =
    useQuery(GET_BATCH_TRANSACTION, {
      variables: {
        year: "2024",
      },
    });

  const { sum: sumOfTodaysTransactions, count: countOfTodaysTransactions } =
    sumTransactionAmountOfToday(
      recentTransaction?.getTransactionReport?.transactionReport,
    );
  const settledAmount = getSettlementAmount(
    settlementData?.getSettlementReports,
  );

  const recentTransactions = getRecentTransactions(
    recentTransaction?.getTransactionReport?.transactionReport,
  );

  const amountOfSchools = partnerData?.partnerProfileData?.totalSchool || 0;
  const kycDetails = partnerData?.partnerProfileData?.kycDetails;

  return (
    <div className="mt-8">
      <div className="grid lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4 mb-4">
        <Card
          amount={transactionAmountDetails?.totalTransactionAmount?.toLocaleString(
            "hi-in",
          )}
          date={"Today"}
          description={"Transaction Amount"}
        />
        <Card
          amount={transactionAmountDetails?.totalTransactions}
          date={"Today"}
          description={"Number of transaction"}
        />
        {user?.getUserQuery.role !== Access.FINANCE_TEAM && (
          <>
            <Card
              amount={(
                Math.floor(commissionTotalAmount * 100) / 100
              ).toLocaleString("hi-in")}
              date={"Current Period"}
              description={"Total commission amount"}
            />
          </>
        )}
        <Card
          amount={amountOfSchools}
          date={"Till date"}
          description={"Total Registered Institutes"}
        />
        <Card
          amount={(Math.floor(settledAmount * 100) / 100).toLocaleString(
            "hi-in",
          )}
          date={"Most Recent"}
          description={"Settlement amount"}
        />
      </div>

      <div
        style={{ gridTemplateRows: "max-content" }}
        className="grid grid-cols-3  grid-rows-2 gap-4 mb-4"
      >
        <div className=" xl:col-span-2 order-1 col-span-3 ">
          <LineGraph dataArray={transactionReport?.getBatchTransactionReport} />
        </div>
        <div className=" xl:col-span-1 xl:order-2 order-3 col-span-3 lg:row-span-2">
          {recentTransactions ? (
            <_Table
              perPage={false}
              exportBtn={false}
              bgColor={" bg-transparent"}
              boxPadding={" p-0"}
              copyRight={false}
              loading={recentLoading}
              description={
                <div className="flex w-full justify-between text-xs pl-4 pr-1">
                  <p className="">Recent transactions</p>
                  <NavLink
                    to="/payments"
                    className="text-[#6687FF] cursor-pointer"
                  >
                    View all
                  </NavLink>
                </div>
              }
              data={[
                ["Date", "Order ID", "Amount"],
                ...recentTransactions?.map((row: any, index: number) => [
                  <div className=" max-w-[5rem]" key={row?.orderID}>
                    {new Date(row?.createdAt).toLocaleString("hi")}
                  </div>,

                  <div className="flex justify-between items-center">
                    <div
                      className="truncate max-w-[7.5rem]"
                      title={row?.collect_id}
                    >
                      {row?.collect_id}
                    </div>
                    <button
                      onClick={() => {
                        handleCopyContent(row?.collect_id);
                      }}
                    >
                      <ToolTip text="Copy Order ID">
                        <MdContentCopy
                          className="cursor-pointer text-[#717171] shrink-0 text-xl"
                          style={{
                            fontSize: "22px",
                            color: "",
                            backgroundColor: "transparent",
                          }}
                        />
                      </ToolTip>
                    </button>
                  </div>,
                  <div
                    key={row?.collect_id}
                  >{`₹${row?.transaction_amount !== null ? (Math.floor(row?.transaction_amount * 100) / 100).toLocaleString("hi-in") : 0}`}</div>,
                ]),
              ]}
            />
          ) : null}
        </div>
        <div className="xl:col-span-2 xl:order-3 order-2 col-span-3 lg:row-span-1 row-span-2">
          <RingGraph
            kycDetails={kycDetails}
            amountOfSchools={amountOfSchools}
          />
        </div>
      </div>
      <CopyRight />
    </div>
  );
}

export default Overview;
